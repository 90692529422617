import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Define the paintings data
const paintingsData = [
  
  {
    id: '1',
    // year: '2018',
    title: 'Celebration',
    medium: 'Acrylic on Canvas',

    size: '36 x 48 in',
    imageUrl: '/paintings/companionship.jpg'
    
  },

 
  {
    id: '2',
    // year: '2018',
    title: 'Lunar Dog',
    medium: 'Acrylic on Canvas',

    size: '36 x 36 in',
    imageUrl: '/paintings/lunar dog_42x42.jpeg'
    
  },
  {
    id: '3',
    title: 'The Connection',
    // year: '2016',
    medium: 'Acrylic on Canvas',
    size: '32 × 32 in',
    imageUrl: '/paintings/the connection_36x36.jpg'
  },
  {
    id: '1',
    title: 'Dancer',
    // year: '2016',
    medium: 'Acrylic on Canvas',
    size: '48 × 60 in',
    imageUrl: '/paintings/dance_36x48.png'
  },

  {
    id: '1',
    // year: '2018',
    title: 'Oozing',
    medium: 'Acrylic on Canvas',

    size: '24 x 36 in',
    imageUrl: '/paintings/oozing_36x48.jpeg'
    
  },
  {
    id: '1',
    // year: '2018',
    title: 'Potente',
    medium: 'Acrylic on Canvas',

    size: '24 x 36 in',
    imageUrl: '/paintings/potente_36x48.jpeg'
    
  },

  {
    id: '1',
    // year: '2018',
    title: 'Flow',
    medium: 'Acrylic on Canvas',

    size: '24 x 36 in',
    imageUrl: '/paintings/flow_36x48.png'
    
  },

  {
    id: '1',
    // year: '2018',
    title: 'Starman',
    medium: 'Acrylic on Canvas',

    size: '48 x 48 in',
    imageUrl: '/paintings/starman_48x48.jpg'
    
  },
  {
    id: '1',
    // year: '2018',
    title: 'Rhapsody',
    medium: 'Acrylic on Canvas',

    size: '60 x 48 in',
    imageUrl: '/paintings/rhapsody_48x36.jpg'
    
  },
  {
    id: '1',
    // year: '2018',
    title: 'Interactive Installations',
    medium: 'Plexi Glass',

    // size: '60 x 48 in',
    imageUrl: '/paintings/interactives.jpg'
    
  },
  {
    id: '8',
    // year: '2018',
    title: 'The Spark',
    medium: 'Acrylic on Canvas',

    size: '48 x 60 in',
    imageUrl: '/paintings/aaa.png'  // Changed from .jpg to .png to match actual file
    
  },
  // {
  //   id: '8',
  //   // year: '2018',
  //   title: 'The Spark',
  //   medium: 'Acrylic on Canvas',

  //   size: '48 x 60 in',
  //   imageUrl: '/paintings/aaa.png'  // Changed from .jpg to .png to match actual file
    
  // },
  {
    id: '8',
    // year: '2018',
    title: 'The Encounter',
    medium: 'Acrylic on Canvas',

    size: '48 x 60 in',
    imageUrl: '/paintings/new.jpg'  // Changed from .jpg to .png to match actual file
    
  },
  //   {
  //   id: '8',
  //   // year: '2018',
  //   title: 'Trumpeter',
  //   medium: 'Acrylic on Canvas',

  //   size: '48 x 60 in',
  //   imageUrl: '/paintings/trumpet.JPG'  // Changed from .jpg to .png to match actual file
  // },

{
id: '8',
// year: '2018',
title: 'Creatures',
medium: 'Acrylic on Canvas',

size: '48 x 60 in',
imageUrl: '/paintings/IMG_0046.JPG'  // Changed from .jpg to .png to match actual file

},

{
  id: '8',
  // year: '2018',
  title: 'wizards with words',
  medium: 'Acrylic on Canvas',
  
  size: '48 x 48 in',
  imageUrl: '/paintings/wizard.png'  // Changed from .jpg to .png to match actual file
  
  },

  {
    id: '8',
    // year: '2018',
    title: 'Dancing J',
    medium: 'Acrylic on Canvas',
    
    size: '36 x 48 in',
    imageUrl: '/paintings/IMG_1310.JPG'  // Changed from .jpg to .png to match actual file
    
    },
    {
      id: '8',
      // year: '2018',
      title: 'Sunset on Mars',
      medium: 'Acrylic on Canvas',
      
      size: '36 x 48 in',
      imageUrl: '/paintings/mars.jpeg'  // Changed from .jpg to .png to match actual file
      
      },
      {
        id: '9',
        // year: '2018',
        title: 'Battery Flowers',
        medium: 'Acrylic on Canvas',
        
        size: '36 x 48 in',
        imageUrl: '/paintings/battery.jpeg'  // Changed from .jpg to .png to match actual file
        
        },
        {
          id: '9',
          // year: '2018',
          title: 'Trumpeter',
          medium: 'Acrylic on Canvas',
          
          size: '24 x 36 in',
          imageUrl: '/paintings/trumpet.JPG'  // Changed from .jpg to .png to match actual file
          
          },
          {
            id: '9',
            // year: '2018',
            title: 'Trumpeter',
            medium: 'Acrylic on Canvas',
            
            size: '24 x 36 in',
            imageUrl: '/paintings/trumpet.JPG'  // Changed from .jpg to .png to match actual file
            
            },
            {
              id: '9',
              // year: '2018',
              title: 'New Science',
              medium: 'Acrylic on Canvas',
              
              size: '24 x 36 in',
              imageUrl: '/paintings/IMG_0019.jpeg'  // Changed from .jpg to .png to match actual file
              
              },
              {
                id: '9',
                // year: '2018',
                title: 'Sprinkled in Destiny',
                medium: 'Acrylic on Canvas',
                
                size: '36 x 48 in',
                imageUrl: '/paintings/destiny.png'  // Changed from .jpg to .png to match actual file
                
                },
                {
                  id: '',
                  // year: '2018',
                  title: 'Meditation',
                  medium: 'Acrylic on Canvas',
              
                  size: '36 x 48 in',
                  imageUrl: '/paintings/meditate.jpeg'
   // {'  // Changed from .jpg to .png to match actual file
                  
                  },
                  {
                    id: '',
                    // year: '2018',
                    title: 'Turtle and Car',
                    medium: 'Interactive Installation with Plexi Glass',
                
                    size: '36 x 48 in',
                    imageUrl: '/paintings/island.png'
     // {'  // Changed from .jpg to .png to match actual file
                    
                    },


    //   id: '1',
  //   // year: '2018',
  //   title: 'Interactive Installations',
  //   medium: 'Plexi Glass',

  //   // size: '60 x 48 in',
  //   imageUrl: '/paintings/new.jpg'
    
  // },
  // {
  //   id: '1',
  //   // year: '2018',
  //   title: 'Interactive Installations',
  //   medium: 'Plexi Glass',

  //   // size: '60 x 48 in',
  //   imageUrl: '/paintings/Screenshot 2024-10-23 at 9.41.52 AM.png'
    
  // },



  // Add more paintings following the same structure
  // Make sure the imageUrl matches your actual file names in the public/paintings folder
];

const cvData = {
  bio: "Adam Jonah is an artist based in NYC. His work bridges the disciplines of art, technology and design. He is driven by a passion for creating positive impact.",
  exhibitions: [
    {
      year: "2024",
      title: "\"Artwalk benefiting Coalition for the Homeless\"",
      location: "Chelsea Factory, NYC"
    },
    {
      year: "2024",
      title: "\"Northwell Health Interactive Art Exhibit\"",
      location: "Monter Cancer Center, NY"
    },
    {
      year: "2018",
      title: "\"ArtRX Symposium\"",
      location: "USC Peck School of Social Work, LA"
    },
    {
      year: "2018",
      title: "\"Artwalk benefiting Coalition for the Homeless\"",
      location: "Spring Studios, NYC"
    },
    {
      year: "2017",
      title: "\"Art Market Hamptons\"",
      location: "The Bridgehampton Museum, NY"
    },
    {
      year: "2017",
      title: "\"Eleven Under Thirty\"",
      location: "Ille Arts Gallery, Amagansett, NY"
    },
    {
      year: "2017",
      title: "\"Artwalk benefiting Coalition for the Homeless\"",
      location: "Spring Studios, NYC"
    },
    {
      year: "2017",
      title: "\"Featured in The Southampton Review\"",
      location: "Spring Edition"
    }
  ],
  review: "An invitation to explore worlds that are simultaneously familiar and fantastical, rooted in nature's own designs yet uniquely original. The Southampton Review"
};

const PaintingsGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div className="main-content">
      <div className="section-header section-nav">
        <span className="section-label">/ PAINTINGS</span>
        <a href="#cv" className="section-label cv-link" onClick={(e) => {
          e.preventDefault();
          document.querySelector('.cv-section').scrollIntoView({ behavior: 'smooth' });
        }}>/ CV</a>
      </div>
      
      {/* Review quote at top */}
      <p className="review-quote">
        <em>The Southampton Review describes Adam's work as "An invitation to explore worlds that are simultaneously familiar and fantastical, rooted in nature's own designs yet uniquely original."</em>
      </p>

      <div className="paintings-gallery">
        {paintingsData.map((painting, index) => (
          <motion.div
            key={index}
            className="painting-item"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: index * 0.1 }}
          >
            <img 
              src={painting.imageUrl} 
              alt={painting.title} 
              className="painting-image"
              onClick={() => setSelectedImage(painting)}
              loading="lazy"
            />
            <div className="painting-details">
              <div className="painting-title">{painting.title}</div>
              <div className="painting-medium">{painting.medium}</div>
              {painting.size && <div className="painting-medium">{painting.size}</div>}
            </div>
          </motion.div>
        ))}
      </div>

      {/* Updated CV Section */}
      <div className="cv-section">
        <div className="section-header">
          <span className="section-label">/ ARTIST CV</span>
        </div>
        
        <p className="cv-bio">{cvData.bio}</p>
        
        <h3 className="exhibitions-title">SELECT EXHIBITIONS</h3>
        <div className="exhibitions-list">
          {cvData.exhibitions.map((exhibition, index) => (
            <div key={index} className="exhibition-item">
              <span className="exhibition-year">{exhibition.year}</span>
              <div className="exhibition-details">
                <div className="exhibition-title">
                  <em>{exhibition.title.replace(/"/g, '')}</em>, {exhibition.location}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal remains the same */}
      <AnimatePresence>
        {selectedImage && (
          <motion.div
            className="painting-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedImage(null)}
          >
            <div className="modal-content" onClick={e => e.stopPropagation()}>
              <img 
                src={selectedImage.imageUrl} 
                alt={selectedImage.title} 
                className="modal-image"
              />
              <button 
                className="close-button"
                onClick={() => setSelectedImage(null)}
              >
                ×
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PaintingsGallery;
